import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import Contacts from '@/components/WebChat/Contacts/Contacts.vue'
import Chat from '@/components/WebChat/Chat/Chat.vue'
import InboxConfig from '@/components/WebChat/InboxConfig/InboxConfig.vue'

export default {
  name: 'Inbox',
  components: {
    HeaderTopDashboard,
    Contacts,
    Chat,
    InboxConfig,
  },
  data: function () {
    return {
      currentComponent: 'chat',
    }
  },
  watch: {

  },
  methods: {
    loadChat () {
      this.currentComponent = 'chat'
      this.$refs.chatComponent.getPrivateWebChatConversations()
    },
    showContacts () {
      this.currentComponent = 'contacts'
      this.loadContacts()
    },
    loadContacts () {
      this.$refs.contactsComponent.getData()
    },
  },
  computed: {

  },

  created () {

  },
}
