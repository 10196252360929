var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-shadow v-card-grey px-0"},[_c('v-card-title',{staticClass:"pt-0 pb-0 card-title-bloque"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t('Web chat inbox'))+" "),_c('span',{staticClass:"subtit_card"},[_vm._v(" "+_vm._s(_vm.$t('Conversaciones'))+" ")])])]),_c('v-col',[_c('div',{staticClass:"buttons-container d-flex justify-end flex-grow-1 mr-10",staticStyle:{"z-index":"0"}},[_c('v-btn-toggle',{staticClass:"pb-1",attrs:{"rounded":"","mandatory":""}},[_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":_vm.loadChat}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra",class:{'selected': _vm.currentComponent === 'chat'}},'v-icon',attrs,false),on),[_vm._v(" far fa-comments ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Conversaciones"))+" ")])])],1),_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"disabled":!_vm.$permissions.hasPermission('view-contacts'),"fab":"","small":"","color":"white","elevation":"0"},on:{"click":_vm.showContacts}},[_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra ",class:{'selected': _vm.currentComponent === 'contacts'}},'v-icon',attrs,false),on),[_vm._v(" fas fa-id-badge ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Contactos"))+" ")])])],1),_c('v-btn',{staticClass:"px-7",staticStyle:{"border":"solid 1px #e9eff1 !important"},attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":function($event){_vm.currentComponent = 'inbox-config'}}},[_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra",class:{'selected': _vm.currentComponent === 'inbox-config'}},'v-icon',attrs,false),on),[_vm._v(" fas fa-cogs ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Configuración')))])])],1)],1)],1)])],1)],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('chat',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentComponent === 'chat'),expression:"currentComponent === 'chat'"}],ref:"chatComponent"}),_c('contacts',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentComponent === 'contacts'),expression:"currentComponent === 'contacts'"}],ref:"contactsComponent"}),_c('inbox-config',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentComponent === 'inbox-config'),expression:"currentComponent === 'inbox-config'"}],ref:"inboxConfigComponent"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }